/*
This software is Copyright ©️ 2020 The University of Southern California. All Rights Reserved. 
Permission to use, copy, modify, and distribute this software and its documentation for educational, research and non-profit purposes, without fee, and without a written agreement is hereby granted, provided that the above copyright notice and subject to the full license file found in the root of this software deliverable. Permission to make commercial use of this software may be obtained by contacting:  USC Stevens Center for Innovation University of Southern California 1150 S. Olive Street, Suite 2300, Los Angeles, CA 90115, USA Email: accounting@stevens.usc.edu

The full terms of this copyright and license should always be found in the root directory of this software deliverable as "license.txt" and if these terms are not found with this software, please contact the USC Stevens Center for the full license.
*/
import {
  ConfigLoadSucceededAction,
  CONFIG_LOAD,
  CONFIG_LOAD_FAILED,
  CONFIG_LOAD_SUCCEEDED,
  DisplayMentorsAction,
  DISPLAY_NUMBER_MENTORS,
  HomePageAction,
  MentorPanelsLoadSucceededAction,
  MentorsLoadSucceededAction,
  MENTORS_LOAD_FAILED,
  MENTORS_LOAD_STARTED,
  MENTORS_LOAD_SUCCEEDED,
  MENTOR_PANELS_LOAD,
  MENTOR_PANELS_LOAD_FAILED,
  MENTOR_PANELS_LOAD_SUCCEEDED,
  OrgCheckSucceededAction,
  ORG_CHECK,
  ORG_CHECK_FAILED,
  ORG_CHECK_SUCCEEDED,
  SessionIdCreatedAction,
  SESSION_ID_CREATED,
  SESSION_ID_FOUND,
  UserDataUpdateAction,
  USER_DATA_FINISH_LOADING,
  USER_DATA_UPDATED,
} from "./actions";
import { DisplaySurveyPopupCondition, LoadStatus, State } from "types";

export const initialState: State = {
  orgCheck: undefined,
  orgCheckStatus: LoadStatus.NONE,
  config: {
    cmi5Enabled: false,
    cmi5Endpoint: process.env.CMI5_ENDPOINT || "",
    cmi5Fetch: process.env.CMI5_FETCH || "",
    styleHeaderTitle: "",
    styleHeaderText: "",
    styleHeaderColor: "",
    styleHeaderTextColor: "",
    styleHeaderLogo: "",
    styleHeaderLogoUrl: "",
    styleHeaderLogoOffset: 0,
    styleHeaderLogoHeight: 0,
    styleHeaderLogoWidth: 0,
    homeFooterColor: "",
    homeFooterTextColor: "",
    homeFooterImages: [],
    homeFooterLinks: [],
    homeBannerColor: "",
    homeBannerButtonColor: "",
    homeCarouselColor: "",
    walkthroughDisabled: false,
    walkthroughTitle: "",
    urlVideoMentorpalWalkthrough: "",
    disclaimerDisabled: false,
    disclaimerTitle: "",
    disclaimerText: "",
    termsOfServiceDisabled: false,
    termsOfServiceText: "",
    displayGuestPrompt: false,
    displaySurveyPopupCondition: DisplaySurveyPopupCondition.USER_ID,
    guestPromptTitle: "",
    guestPromptText: "",
    guestPromptInputType: "Email",
    activeMentors: [],
    activeMentorPanels: [],
    featuredMentors: [],
    featuredMentorPanels: [],
    featuredSubjects: [],
    featuredKeywordTypes: [],
    defaultSubject: "",
    postSurveyLink: "",
    postSurveyTimer: 0,
    postSurveyUserIdEnabled: true,
    postSurveyReferrerEnabled: false,
    surveyButtonInDisclaimer: "OFF",
  },
  configLoadStatus: LoadStatus.NONE,
  mentors: [],
  mentorsLoadStatus: LoadStatus.NONE,
  mentorPanels: [],
  mentorPanelsLoadStatus: LoadStatus.NONE,
  numberOfMentors: 5,
  panelSubject: "",
  sessionId: "",
  userDataLoadStatus: LoadStatus.LOAD_IN_PROGRESS,
  userData: {
    xapiUserEmail: "",
    givenUserEmail: "",
    givenUserId: "",
    referrer: "",
    events: [],
  },
};

function onSessionIdCreated(
  state: State,
  action: SessionIdCreatedAction
): State {
  // add new sessionId to url in the case of the back button
  const url = new URL(window.location.href);
  url.searchParams.delete("sessionId");
  url.searchParams.append("sessionId", action.payload);
  window.history.pushState({ path: url.href }, "", url.href);
  return {
    ...state,
    sessionId: action.payload,
  };
}

// MENTOR FUNCTIONS
function onMentorsLoadStarted(state: State): State {
  return {
    ...state,
    mentorsLoadStatus: LoadStatus.LOAD_IN_PROGRESS,
  };
}

function onMentorLoadFailed(state: State): State {
  return {
    ...state,
    mentorsLoadStatus: LoadStatus.LOAD_FAILED,
  };
}

function onMentorsLoadSucceeded(
  state: State,
  action: MentorsLoadSucceededAction
): State {
  return {
    ...state,
    mentors: action.payload,
    mentorsLoadStatus: LoadStatus.LOADED,
  };
}

// MENTOR PANELS
function onMentorPanelsLoadStarted(state: State): State {
  return {
    ...state,
    mentorPanelsLoadStatus: LoadStatus.LOAD_IN_PROGRESS,
  };
}

function onMentorPanelsloadFailed(state: State): State {
  return {
    ...state,
    mentorPanelsLoadStatus: LoadStatus.LOAD_FAILED,
  };
}

function onMentorPanelsLoadSucceeded(
  state: State,
  action: MentorPanelsLoadSucceededAction
): State {
  return {
    ...state,
    mentorPanels: action.payload,
    mentorPanelsLoadStatus: LoadStatus.LOADED,
  };
}

// DISPLAY NUMBER OF MENTORS
function onDisplayNumberMentors(
  state: State,
  action: DisplayMentorsAction
): State {
  return {
    ...state,
    numberOfMentors: action.payload.totalMentors,
  };
}

// CONFIG
function onConfigLoadStarted(state: State): State {
  return {
    ...state,
    configLoadStatus: LoadStatus.LOAD_IN_PROGRESS,
  };
}

function onConfigLoadFailed(state: State): State {
  return {
    ...state,
    configLoadStatus: LoadStatus.LOAD_FAILED,
  };
}

function onConfigLoadSucceeded(
  state: State,
  action: ConfigLoadSucceededAction
): State {
  return {
    ...state,
    config: action.payload,
    configLoadStatus: LoadStatus.LOADED,
  };
}

// ORG CHECK
function onOrgCheckStarted(state: State): State {
  return {
    ...state,
    orgCheckStatus: LoadStatus.LOAD_IN_PROGRESS,
  };
}

function onOrgCheckFailed(state: State): State {
  return {
    ...state,
    orgCheckStatus: LoadStatus.LOAD_FAILED,
  };
}

function onOrgCheckSucceeded(
  state: State,
  action: OrgCheckSucceededAction
): State {
  return {
    ...state,
    orgCheck: action.payload,
    orgCheckStatus: LoadStatus.LOADED,
  };
}

function onUserDataUpdated(state: State, action: UserDataUpdateAction): State {
  return {
    ...state,
    userData: {
      ...state.userData,
      ...action.payload,
    },
  };
}

function onUserDataFinishLoading(
  state: State,
  action: UserDataUpdateAction
): State {
  return {
    ...state,
    userDataLoadStatus: LoadStatus.LOADED,
    userData: {
      ...state.userData,
      ...action.payload,
    },
  };
}

export default function reducer(
  state = initialState,
  action: HomePageAction
): State {
  switch (action.type) {
    // USER DATA
    case USER_DATA_UPDATED:
      return onUserDataUpdated(state, action);
    case USER_DATA_FINISH_LOADING:
      return onUserDataFinishLoading(state, action);
    //SESSION ID
    case SESSION_ID_FOUND:
    case SESSION_ID_CREATED:
      return onSessionIdCreated(state, action);
    // DISCLAIMER TEXT
    case CONFIG_LOAD:
      return onConfigLoadStarted(state);
    case CONFIG_LOAD_FAILED:
      return onConfigLoadFailed(state);
    case CONFIG_LOAD_SUCCEEDED:
      return onConfigLoadSucceeded(state, action);
    // ORG
    case ORG_CHECK:
      return onOrgCheckStarted(state);
    case ORG_CHECK_FAILED:
      return onOrgCheckFailed(state);
    case ORG_CHECK_SUCCEEDED:
      return onOrgCheckSucceeded(state, action);
    // MENTORS
    case MENTORS_LOAD_STARTED:
      return onMentorsLoadStarted(state);
    case MENTORS_LOAD_FAILED:
      return onMentorLoadFailed(state);
    case MENTORS_LOAD_SUCCEEDED:
      return onMentorsLoadSucceeded(state, action);
    // MENTOR PANELS
    case MENTOR_PANELS_LOAD:
      return onMentorPanelsLoadStarted(state);
    case MENTOR_PANELS_LOAD_FAILED:
      return onMentorPanelsloadFailed(state);
    case MENTOR_PANELS_LOAD_SUCCEEDED:
      return onMentorPanelsLoadSucceeded(state, action);
    // DISPLAY NUMBER OF MENTORS
    case DISPLAY_NUMBER_MENTORS:
      return onDisplayNumberMentors(state, action);
    default:
      return state;
  }
}
