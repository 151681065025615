/*
This software is Copyright ©️ 2020 The University of Southern California. All Rights Reserved. 
Permission to use, copy, modify, and distribute this software and its documentation for educational, research and non-profit purposes, without fee, and without a written agreement is hereby granted, provided that the above copyright notice and subject to the full license file found in the root of this software deliverable. Permission to make commercial use of this software may be obtained by contacting:  USC Stevens Center for Innovation University of Southern California 1150 S. Olive Street, Suite 2300, Los Angeles, CA 90115, USA Email: accounting@stevens.usc.edu

The full terms of this copyright and license should always be found in the root directory of this software deliverable as "license.txt" and if these terms are not found with this software, please contact the USC Stevens Center for the full license.
*/
/* eslint-disable */
import { ThunkDispatch } from "redux-thunk";

import {
  fetchConfig,
  fetchMentorPanels,
  fetchMentors,
  fetchOrgPerm,
} from "api";
import {
  State,
  Config,
  MentorPanel,
  Mentor,
  OrgCheckPermission,
} from "../types";
import { LocalStorageUserData } from "utils";

// SESSION ID
export const SESSION_ID_CREATED = "SESSION_ID_CREATED";
export const SESSION_ID_FOUND = "SESSION_ID_FOUND";

// USER DATA
export const USER_DATA_UPDATED = "USER_DATA_UPDATED";
export const USER_DATA_FINISH_LOADING = "USER_DATA_FINISH_LOADING";

// MENTORS
export const MENTORS_LOAD_STARTED = "MENTORS_LOAD_STARTED";
export const MENTORS_LOAD_SUCCEEDED = "MENTORS_LOAD_SUCCEEDED";
export const MENTORS_LOAD_REQUESTED = "MENTORS_LOAD_REQUESTED";
export const MENTORS_LOAD_FAILED = "MENTORS_LOAD_FAILED";

// FEATURED MENTORS
export const FEATURED_MENTORS_LOAD = "FEATURED_MENTORS_LOAD";
export const FEATURED_MENTORS_LOAD_REQUESTED =
  "FEATURED_MENTORS_LOAD_REQUESTED";
export const FEATURED_MENTORS_LOAD_SUCCEEDED =
  "FEATURED_MENTORS_LOAD_SUCCEEDED";
export const FEATURED_MENTORS_LOAD_FAILED = "FEATURED_MENTORS_LOAD_FAILED";

// FEATURED MENTORS
export const ACTIVE_MENTORS_LOAD = "ACTIVE_MENTORS_LOAD";
export const ACTIVE_MENTORS_LOAD_REQUESTED = "ACTIVE_MENTORS_LOAD_REQUESTED";
export const ACTIVE_MENTORS_LOAD_SUCCEEDED = "ACTIVE_MENTORS_LOAD_SUCCEEDED";
export const ACTIVE_MENTORS_LOAD_FAILED = "ACTIVE_MENTORS_LOAD_FAILED";

// MENTOR PANELS
export const MENTOR_PANELS_LOAD = "MENTOR_PANELS_LOAD";
export const MENTOR_PANELS_LOAD_REQUESTED = "MENTOR_PANELS_LOAD_REQUESTED";
export const MENTOR_PANELS_LOAD_SUCCEEDED = "MENTOR_PANELS_LOAD_SUCCEEDED";
export const MENTOR_PANELS_LOAD_FAILED = "MENTOR_PANELS_LOAD_FAILED";

// DISPLAY NUMBER OF MENTORS
export const DISPLAY_NUMBER_MENTORS = "DISPLAY_NUMBER_MENTORS";

// DISCLAIMER_TEXT
export const DISCLAIMER_TEXT_LOAD = "DISCLAIMER_TEXT_LOAD";
export const DISCLAIMER_TEXT_LOAD_REQUESTED = "DISCLAIMER_TEXT_LOAD_REQUESTED";
export const DISCLAIMER_TEXT_LOAD_SUCCEEDED = "DISCLAIMER_TEXT_LOAD_SUCCEEDED";
export const DISCLAIMER_TEXT_LOAD_FAILED = "DISCLAIMER_TEXT_LOAD_FAILED";

// CONFIG
export const CONFIG_LOAD = "CONFIG_LOAD";
export const CONFIG_LOAD_REQUESTED = "CONFIG_LOAD_REQUESTED";
export const CONFIG_LOAD_SUCCEEDED = "CONFIG_LOAD_SUCCEEDED";
export const CONFIG_LOAD_FAILED = "CONFIG_LOAD_FAILED";

// ORG CHECK
export const ORG_CHECK = "ORG_CHECK";
export const ORG_CHECK_REQUESTED = "ORG_CHECK_REQUESTED";
export const ORG_CHECK_SUCCEEDED = "ORG_CHECK_SUCCEEDED";
export const ORG_CHECK_FAILED = "ORG_CHECK_FAILED";

export interface SessionIdCreatedAction {
  type: typeof SESSION_ID_CREATED | typeof SESSION_ID_FOUND;
  payload: string;
}

export interface UserDataUpdateAction {
  type: typeof USER_DATA_UPDATED | typeof USER_DATA_FINISH_LOADING;
  payload: Partial<LocalStorageUserData>;
}

//  MENTORS ACTIONS
export interface MentorsLoadStartedAction {
  type: typeof MENTORS_LOAD_STARTED;
}

export interface MentorsLoadRequestAction {
  type: typeof MENTORS_LOAD_REQUESTED;
  payload: Mentor[];
}

export interface MentorsLoadFailedAction {
  type: typeof MENTORS_LOAD_FAILED;
  errors: string[];
}

export interface MentorsLoadSucceededAction {
  type: typeof MENTORS_LOAD_SUCCEEDED;
  payload: Mentor[];
}

// MENTOR PANELS
export interface MentorPanelsLoadStartedAction {
  type: typeof MENTOR_PANELS_LOAD;
}

export interface MentorPanelsLoadRequestAction {
  type: typeof MENTOR_PANELS_LOAD_REQUESTED;
  payload: MentorPanel[];
}

export interface MentorPanelsLoadSucceededAction {
  type: typeof MENTOR_PANELS_LOAD_SUCCEEDED;
  payload: MentorPanel[];
}

export interface MentorPanelsLoadFailedAction {
  type: typeof MENTOR_PANELS_LOAD_FAILED;
  errors: string[];
}

// DISPLAY NUMBER OF MENTORS
export interface DisplayMentorsAction {
  type: typeof DISPLAY_NUMBER_MENTORS;
  payload: {
    totalMentors: number;
  };
}

// CONFIG ACTIONS
export interface ConfigLoadStartedAction {
  type: typeof CONFIG_LOAD;
}
export interface ConfigLoadRequestAction {
  type: typeof CONFIG_LOAD_REQUESTED;
  payload: Config;
}
export interface ConfigLoadSucceededAction {
  type: typeof CONFIG_LOAD_SUCCEEDED;
  payload: Config;
}
export interface ConfigLoadFailedAction {
  type: typeof CONFIG_LOAD_FAILED;
  errors: string[];
}

// ORG ACTIONS
export interface OrgCheckStartedAction {
  type: typeof ORG_CHECK;
}
export interface OrgCheckRequestAction {
  type: typeof ORG_CHECK_REQUESTED;
  payload: OrgCheckPermission;
}
export interface OrgCheckSucceededAction {
  type: typeof ORG_CHECK_SUCCEEDED;
  payload: OrgCheckPermission;
}
export interface OrgCheckFailedAction {
  type: typeof ORG_CHECK_FAILED;
  errors: string[];
}

// MENTOR GROUP ACTIONS
export type MentorsLoadAction =
  | MentorsLoadRequestAction
  | MentorsLoadFailedAction
  | MentorsLoadSucceededAction
  | MentorsLoadStartedAction;

export type MentorPanelsLoadAction =
  | MentorPanelsLoadStartedAction
  | MentorPanelsLoadRequestAction
  | MentorPanelsLoadSucceededAction
  | MentorPanelsLoadFailedAction;

export type ConfigAction =
  | ConfigLoadStartedAction
  | ConfigLoadRequestAction
  | ConfigLoadSucceededAction
  | ConfigLoadFailedAction;

export type CheckOrgAction =
  | OrgCheckStartedAction
  | OrgCheckRequestAction
  | OrgCheckSucceededAction
  | OrgCheckFailedAction;

export type HomePageAction =
  | MentorsLoadAction
  | MentorPanelsLoadAction
  | DisplayMentorsAction
  | ConfigAction
  | CheckOrgAction
  | SessionIdCreatedAction
  | UserDataUpdateAction;

export const checkOrgPermission =
  (accessCode?: string) =>
  async (dispatch: ThunkDispatch<State, void, CheckOrgAction>) => {
    dispatch({
      type: ORG_CHECK,
    });
    try {
      const orgCheck = await fetchOrgPerm(accessCode);
      return dispatch({
        type: ORG_CHECK_SUCCEEDED,
        payload: orgCheck,
      });
    } catch (err) {
      console.error(err);
      if (err instanceof Error) {
        return dispatch({
          type: ORG_CHECK_FAILED,
          errors: [err.message],
        });
      }
    }
  };

export const loadConfig =
  (accessCode?: string) =>
  async (dispatch: ThunkDispatch<State, void, ConfigAction>) => {
    dispatch({
      type: CONFIG_LOAD,
    });
    try {
      const config = await fetchConfig(accessCode);
      return dispatch({
        type: CONFIG_LOAD_SUCCEEDED,
        payload: config,
      });
    } catch (err) {
      console.error(err);
      if (err instanceof Error) {
        return dispatch({
          type: CONFIG_LOAD_FAILED,
          errors: [err.message],
        });
      }
    }
  };

export const loadMentors =
  (config: Config) =>
  async (dispatch: ThunkDispatch<State, void, MentorsLoadAction>) => {
    dispatch({
      type: MENTORS_LOAD_STARTED,
    });
    try {
      const mentors = await fetchMentors(config);
      return dispatch({
        type: MENTORS_LOAD_SUCCEEDED,
        payload: mentors,
      });
    } catch (err) {
      console.error(err);
      if (err instanceof Error) {
        return dispatch({
          type: MENTORS_LOAD_FAILED,
          errors: [err.message],
        });
      }
    }
  };

export const loadMentorPanels =
  (config: Config) =>
  async (dispatch: ThunkDispatch<State, void, MentorPanelsLoadAction>) => {
    dispatch({
      type: MENTOR_PANELS_LOAD,
    });
    try {
      const mentorPanels = await fetchMentorPanels(config);
      const filteredMentorPanels: MentorPanel[] = mentorPanels.map((panel) => {
        const filteredMentors = panel.mentorData.filter(
          (mentor) => mentor.isPublicApproved
        );
        return {
          ...panel,
          mentorData: filteredMentors,
          mentors: filteredMentors.map((mentor) => mentor.id || ""),
        };
      });
      const filteredMentorPanelsWithMentors = filteredMentorPanels.filter(
        (panel) => panel.mentors.length > 0
      );

      return dispatch({
        type: MENTOR_PANELS_LOAD_SUCCEEDED,
        payload: filteredMentorPanelsWithMentors,
      });
    } catch (err) {
      console.error(err);
      if (err instanceof Error) {
        return dispatch({
          type: MENTOR_PANELS_LOAD_FAILED,
          errors: [err.message],
        });
      }
    }
  };

export const numberDisplayMentors =
  (totalMentors: number) =>
  async (dispatch: ThunkDispatch<State, void, DisplayMentorsAction>) => {
    dispatch({
      type: DISPLAY_NUMBER_MENTORS,
      payload: {
        totalMentors: totalMentors,
      },
    });
  };
