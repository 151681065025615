/*
This software is Copyright ©️ 2020 The University of Southern California. All Rights Reserved. 
Permission to use, copy, modify, and distribute this software and its documentation for educational, research and non-profit purposes, without fee, and without a written agreement is hereby granted, provided that the above copyright notice and subject to the full license file found in the root of this software deliverable. Permission to make commercial use of this software may be obtained by contacting:  USC Stevens Center for Innovation University of Southern California 1150 S. Olive Street, Suite 2300, Los Angeles, CA 90115, USA Email: accounting@stevens.usc.edu

The full terms of this copyright and license should always be found in the root directory of this software deliverable as "license.txt" and if these terms are not found with this software, please contact the USC Stevens Center for the full license.
*/
import { v4 as uuid } from "uuid";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { sendCmi5Statement, toXapiResultExtCustom } from "cmiutils";
import {
  EVENTS_KEY,
  LS_EMAIL_KEY,
  LS_USER_ID_KEY,
  LS_X_API_EMAIL_KEY,
  POST_SURVEY_TIME_KEY,
  QUALTRICS_USER_ID_URL_PARAM_KEY,
  REFERRER_KEY,
  REGISTRATION_ID_KEY,
  TIME_SPENT_ON_PAGE_KEY,
} from "local-constants";

export function normalizeString(s: string): string {
  return s.replace(/\W+/g, "").normalize().toLowerCase();
}

export function chromeVersion(): number {
  // eslint-disable-next-line no-undef
  if (typeof navigator === `undefined`) {
    return 0;
  }
  // eslint-disable-next-line no-undef
  const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
  return raw ? parseInt(raw[2], 10) : 0;
}

export function setLocalStorage(key: string, value: string): void {
  if (typeof window == "undefined") {
    return;
  }
  localStorage.setItem(key, value);
  window.dispatchEvent(new Event("storage"));
}

export function getLocalStorage(key: string): string {
  if (typeof window == "undefined") {
    return "";
  }
  return localStorage.getItem(key) || "";
}

export function removeLocalStorageItem(key: string): void {
  if (typeof window == "undefined") {
    return;
  }
  localStorage.removeItem(key);
}

export function printLocalStorage(): void {
  if (typeof window == "undefined") {
    return;
  }
  console.info("local storage");
  for (let i = 0; i < localStorage.length; i++) {
    console.info(
      localStorage.key(i) +
        "=[" +
        localStorage.getItem(localStorage.key(i) || "") +
        "]"
    );
  }
}

export function getRegistrationId(): string {
  const registrationIdFromUrl = new URL(location.href).searchParams.get(
    REGISTRATION_ID_KEY
  );
  if (!registrationIdFromUrl) {
    const registrationIdFromStorage = getLocalStorage(REGISTRATION_ID_KEY);
    if (!registrationIdFromStorage) {
      return uuid();
    }
    return registrationIdFromStorage;
  }
  return registrationIdFromUrl;
}

/*
User comes from survey, with userid in URL parameters
  - userid = userid
  - email = userid.urlparam@mentorpal.org
*/
export const getUserIdFromURL = (): string => {
  const fetch = new URL(location.href).searchParams.get("fetch");
  const userIfFetch =
    fetch && isUrl(fetch)
      ? new URL(fetch).searchParams.get(QUALTRICS_USER_ID_URL_PARAM_KEY)
      : "";
  const userIdfromURL = new URL(location.href).searchParams.get(
    QUALTRICS_USER_ID_URL_PARAM_KEY
  );
  return userIdfromURL || userIfFetch || "";
};

export const isUrl = (string: string): boolean => {
  return string.startsWith("http:") || string.startsWith("https:");
};

export const getParamURL = (param: string): string => {
  const paramFromURL = new URL(location.href).searchParams.get(param);
  if (paramFromURL) {
    return paramFromURL;
  }
  return "";
};

export const cleanUserEmail = (userEmailField: string): string => {
  if (!userEmailField) {
    return "";
  }
  return `${userEmailField
    .replace("@", "-")
    .replace(".", "-")
    .replace("_", "-")}`;
};

export function loadSentry(): void {
  Sentry.init({
    dsn: "https://d4d3d9d3c0944397b69a2f3150ad13d4@o1081855.ingest.sentry.io/6419248",
    integrations: [new BrowserTracing()],
    tracesSampleRate: process.env.STAGE == "prod" ? 0.2 : 0.0,
    environment: process.env.STAGE,
  });
}

export const validatedEmail = (email: string | null): string => {
  if (!email) {
    return "";
  }
  const re = /\S+@\S+\.\S+/;
  if (re.test(email)) {
    return email;
  }
  return generateFromWrongEmail(email);
};

const generateFromWrongEmail = (wrongEmail: string): string => {
  return `${cleanWrongEmail(wrongEmail)}@mentorpal.org`;
};

export const cleanWrongEmail = (email: string | null): string => {
  if (!email) {
    return "";
  }
  return email.replace("@", "-").replace(".", "-").replace("_", "-");
};

export function onVisibilityChange(sessionId: string): void {
  if (document.visibilityState !== "visible") {
    const data: LocalStorageUserData = getLocalStorageUserData();
    const xApiuserData = {
      verb: "suspended",
      userid: data[LS_USER_ID_KEY],
      userEmail: data[LS_X_API_EMAIL_KEY],
      referrer: data[REFERRER_KEY],
      postSurveyTime: getLocalStorage(POST_SURVEY_TIME_KEY),
      timeSpentOnPage: getLocalStorage(TIME_SPENT_ON_PAGE_KEY),
      qualtricsUserId: getLocalStorage(LS_USER_ID_KEY),
    };
    sendCmi5Statement(
      {
        verb: {
          id: `https://mentorpal.org/xapi/verb/${xApiuserData.verb}`,
          display: {
            "en-US": `${xApiuserData.verb}`,
          },
        },
        result: {
          extensions: {
            "https://mentorpal.org/xapi/verb/suspended": toXapiResultExtCustom(
              xApiuserData.verb,
              xApiuserData.userid,
              xApiuserData.userEmail,
              xApiuserData.referrer,
              xApiuserData.postSurveyTime,
              xApiuserData.timeSpentOnPage,
              xApiuserData.qualtricsUserId
            ),
          },
        },
        object: {
          id: `${window.location.protocol}//${window.location.host}`,
          objectType: "Activity",
        },
      },
      sessionId
    );
  }
}

export function XOR(a: boolean, b: boolean): boolean {
  return (a || b) && !(a && b);
}

export type LocalStorageKeys =
  | typeof LS_USER_ID_KEY
  | typeof LS_EMAIL_KEY
  | typeof LS_X_API_EMAIL_KEY;

export function updateLocalStorageUserData(
  updatedObject: Partial<LocalStorageUserData>
): LocalStorageUserData {
  const localData: LocalStorageUserData = getLocalStorageUserData();
  const newData = { ...localData, ...updatedObject };
  setLocalStorage("userData", JSON.stringify(newData));
  return localData;
}

export interface LocalStorageUserData {
  [LS_USER_ID_KEY]: string;
  [LS_EMAIL_KEY]: string;
  [LS_X_API_EMAIL_KEY]: string;
  [REFERRER_KEY]: string;
  [EVENTS_KEY]: string[];
}

export function getLocalStorageUserData(): LocalStorageUserData {
  const localData = JSON.parse(localStorage.getItem("userData") || "{}");
  const localGivenUserId = localData[LS_USER_ID_KEY] || "";
  const localEmail = localData[LS_EMAIL_KEY] || "";
  const localXapiEmail = localData[LS_X_API_EMAIL_KEY] || "";
  const localReferrer = localData[REFERRER_KEY] || "";
  const localEvents = localData[EVENTS_KEY] || [];
  return {
    [LS_USER_ID_KEY]: localGivenUserId,
    [LS_EMAIL_KEY]: localEmail,
    [LS_X_API_EMAIL_KEY]: localXapiEmail,
    [REFERRER_KEY]: localReferrer,
    [EVENTS_KEY]: localEvents,
  };
}

export function resetTimeSpentOnPage(): void {
  const curTimeSpentOnPage = getLocalStorage(TIME_SPENT_ON_PAGE_KEY);
  if (!curTimeSpentOnPage) {
    return;
  }
  setLocalStorage(TIME_SPENT_ON_PAGE_KEY, "0");
}

export function emailFromUserId(userId: string): string {
  return `${userId}@mentorpal.org`;
}
