/*
This software is Copyright ©️ 2020 The University of Southern California. All Rights Reserved. 
Permission to use, copy, modify, and distribute this software and its documentation for educational, research and non-profit purposes, without fee, and without a written agreement is hereby granted, provided that the above copyright notice and subject to the full license file found in the root of this software deliverable. Permission to make commercial use of this software may be obtained by contacting:  USC Stevens Center for Innovation University of Southern California 1150 S. Olive Street, Suite 2300, Los Angeles, CA 90115, USA Email: accounting@stevens.usc.edu

The full terms of this copyright and license should always be found in the root directory of this software deliverable as "license.txt" and if these terms are not found with this software, please contact the USC Stevens Center for the full license.
*/
import axios from "axios";
import {
  Config,
  MentorPanel,
  Connection,
  Mentor,
  Subject,
  Keyword,
  OrgCheckPermission,
} from "types";
import * as queries from "./queries";

export const GRAPHQL_ENDPOINT =
  process.env.GATSBY_GRAPHQL_ENDPOINT || "/graphql";
export const CLIENT_ENDPOINT = process.env.CLIENT_ENDPOINT || "/chat";

interface GraphQLResponse<T> {
  errors?: { message: string }[];
  data?: T;
}

export async function fetchOrgPerm(
  orgAccessCode?: string
): Promise<OrgCheckPermission> {
  const gqlRes = await axios.post<
    GraphQLResponse<{ orgCheckPermission: OrgCheckPermission }>
  >(GRAPHQL_ENDPOINT, {
    query: queries.checkOrgPermission,
    variables: {
      orgAccessCode: orgAccessCode,
    },
  });
  if (gqlRes.status !== 200) {
    throw new Error(`OrgCheckPermission load failed: ${gqlRes.statusText}}`);
  }
  if (gqlRes.data.errors) {
    throw new Error(
      `errors reponse to orgCheckPermission query: ${JSON.stringify(
        gqlRes.data.errors
      )}`
    );
  }
  if (!gqlRes.data.data) {
    throw new Error(
      `no data in non-error reponse: ${JSON.stringify(gqlRes.data)}`
    );
  }
  return gqlRes.data.data.orgCheckPermission;
}

export async function fetchConfig(orgAccessCode?: string): Promise<Config> {
  const gqlRes = await axios.post<GraphQLResponse<{ orgConfig: Config }>>(
    GRAPHQL_ENDPOINT,
    {
      query: queries.getOrgConfig,
      variables: {
        orgAccessCode: orgAccessCode,
      },
    }
  );
  if (gqlRes.status !== 200) {
    throw new Error(`Config load failed: ${gqlRes.statusText}}`);
  }
  if (gqlRes.data.errors) {
    throw new Error(
      `errors reponse to orgConfig query: ${JSON.stringify(gqlRes.data.errors)}`
    );
  }
  if (!gqlRes.data.data) {
    throw new Error(
      `no data in non-error reponse: ${JSON.stringify(gqlRes.data)}`
    );
  }
  return gqlRes.data.data.orgConfig;
}

export async function fetchMentors(config: Config): Promise<Mentor[]> {
  const mentorIds = [
    ...new Set([...config.featuredMentors, ...config.activeMentors]),
  ];
  const gqlRes = await axios.post<
    GraphQLResponse<{ mentors: Connection<Mentor> }>
  >(GRAPHQL_ENDPOINT, {
    query: queries.getMentors,
    variables: {
      filter: { _id: { $in: mentorIds } },
    },
  });
  if (gqlRes.status !== 200) {
    throw new Error(`Mentors load failed: ${gqlRes.statusText}}`);
  }
  if (gqlRes.data.errors) {
    throw new Error(
      `errors reponse to mentors query: ${JSON.stringify(gqlRes.data.errors)}`
    );
  }
  if (!gqlRes.data.data) {
    throw new Error(
      `no data in non-error reponse: ${JSON.stringify(gqlRes.data)}`
    );
  }
  return ConnectionToArray<Mentor>(gqlRes.data.data.mentors).filter(
    (mentor) => mentor.isPublicApproved
  );
}

export async function fetchMentorPanels(
  config: Config
): Promise<MentorPanel[]> {
  const mentorPanelIds = [
    ...new Set([...config.featuredMentorPanels, ...config.activeMentorPanels]),
  ];
  const gqlRes = await axios.post<
    GraphQLResponse<{ mentorPanels: Connection<MentorPanel> }>
  >(GRAPHQL_ENDPOINT, {
    query: queries.getMentorPanels,
    variables: {
      filter: { _id: { $in: mentorPanelIds } },
    },
  });
  if (gqlRes.status !== 200) {
    throw new Error(`Mentor Panels load failed: ${gqlRes.statusText}}`);
  }
  if (gqlRes.data.errors) {
    throw new Error(
      `errors reponse to mentor panels query: ${JSON.stringify(
        gqlRes.data.errors
      )}`
    );
  }
  if (!gqlRes.data.data) {
    throw new Error(
      `no data in non-error reponse: ${JSON.stringify(gqlRes.data)}`
    );
  }
  return ConnectionToArray<MentorPanel>(gqlRes.data.data.mentorPanels);
}

export async function fetchSubjects(config: Config): Promise<Subject[]> {
  const gqlRes = await axios.post<
    GraphQLResponse<{ subjects: Connection<Subject> }>
  >(GRAPHQL_ENDPOINT, {
    query: queries.getSubjects,
    variables: {
      filter: { _id: { $in: config.featuredSubjects } },
    },
  });
  if (gqlRes.status !== 200) {
    throw new Error(`Subjects query failed: ${gqlRes.statusText}}`);
  }
  if (gqlRes.data.errors) {
    throw new Error(
      `errors reponse to subjects query: ${JSON.stringify(gqlRes.data.errors)}`
    );
  }
  if (!gqlRes.data.data) {
    throw new Error(
      `no data in non-error reponse: ${JSON.stringify(gqlRes.data)}`
    );
  }
  return ConnectionToArray<Subject>(gqlRes.data.data.subjects);
}

export async function fetchKeywords(config: Config): Promise<Keyword[]> {
  const gqlRes = await axios.post<
    GraphQLResponse<{ keywords: Connection<Keyword> }>
  >(GRAPHQL_ENDPOINT, {
    query: queries.getKeywords,
    variables: {
      filter: { type: { $in: config.featuredKeywordTypes } },
    },
  });
  if (gqlRes.status !== 200) {
    throw new Error(`Keywords query failed: ${gqlRes.statusText}}`);
  }
  if (gqlRes.data.errors) {
    throw new Error(
      `errors reponse to keywords query: ${JSON.stringify(gqlRes.data.errors)}`
    );
  }
  if (!gqlRes.data.data) {
    throw new Error(
      `no data in non-error reponse: ${JSON.stringify(gqlRes.data)}`
    );
  }
  return ConnectionToArray<Keyword>(gqlRes.data.data.keywords);
}

export async function fetchMentorsByKeyword(args: {
  keywords?: string[];
  subject?: string;
  sortBy?: string;
  sortAscending?: boolean;
}): Promise<Mentor[]> {
  const gqlRes = await axios.post<
    GraphQLResponse<{ mentorsByKeyword: Mentor[] }>
  >(GRAPHQL_ENDPOINT, {
    query: queries.getMentorsByKeyword,
    variables: {
      keywords: args.keywords,
      subject: args.subject,
      sortBy: args.sortBy,
      sortAscending: args.sortAscending,
    },
  });
  if (gqlRes.status !== 200) {
    throw new Error(`Mentors by keyword query failed: ${gqlRes.statusText}}`);
  }
  if (gqlRes.data.errors) {
    throw new Error(
      `errors reponse to mentors by keyword query: ${JSON.stringify(
        gqlRes.data.errors
      )}`
    );
  }
  if (!gqlRes.data.data) {
    throw new Error(
      `no data in non-error reponse: ${JSON.stringify(gqlRes.data)}`
    );
  }
  return gqlRes.data.data.mentorsByKeyword;
}

function ConnectionToArray<T>(connection: Connection<T>): T[] {
  return connection.edges.map((e) => e.node);
}
